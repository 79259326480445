
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  direction: rtl;
  margin: 0;
  font-family:  'Open Sans Hebrew', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#2e384d;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h2
{
  font-family: "Open Sans Hebrew bold";
  font-size: 24px;
  color:#2e384d;
}
h2:after{
  content: ' ';
  width: 184px;
  height: 4px;
  border-radius: 2px;
  display: block;
  margin-bottom: 15px;
  background-color: #FBD54A;
  }
  legend,h3
  {
    font-family: "Open Sans Hebrew bold";
    font-size: 18px;
    color:#2e384d;
  }
#ModLoginApp
{
  display: flex;
  justify-content: center;
  background: url('assets/images/loginbackground.png') no-repeat;
  background-size: cover;
  background-position-y: bottom;
  min-height: 100vh;
  display: block;
}



@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.rotate {
  -webkit-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
}

.progressBar{
    width: 90px !important;
    height: 90px!important;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 100000;
}
.MuiCircularProgress-colorPrimary
{
  color:#fdcd46 !important;
}
.registration-button{
  font-family:"Open Sans Hebrew bold";
  height: 52px;	
  width: 387px;	
  border-radius: 26px;	
  background-color:  #fdba10 ;
  box-shadow: 0 7px 12px -5px  #fdba10 ;
  color: #FFFFFF;
  font-size: 20px;	
  /* font-weight: bold;	 */
  letter-spacing: 0.09px;	
  line-height: 32px;
  border: none;
  cursor: pointer;
}

/*form general classes*/
.formContainer{
  display:flex;
  flex-direction: column;
  flex-grow: 1;
}
/* .formField{
  width:386px;
  height: 56px;
  margin-bottom: 20px !important
} */
.phoneSection
{
  display: flex;
  width:65%;
}
.ModLogin .formRow.cols.phonecol
{
    justify-content: start;
    padding-right: 30px;
}
.ModLogin .formRow.cols.phonecol.phoneTwocol
{
  justify-content: center;
  padding-right: 0;
}
.phonecol .phoneSection
{
    width: 50%;
}
.ModLogin .phoneSection .formFieldPhone,.ModLogin .ModRgistration .formField.formFieldPhone
{
  width:80%;
}
.ModLogin .phoneSection .formFieldPhonePrefix,.ModLogin .ModRgistration .formField.formFieldPhonePrefix
{
  width:20%;
}
.formField
{
  width:45%;

}
.formFieldPhone
{
  width:80%;
  /* direction: ltr; */
}
.formFieldPhonePrefix
{
  width:20%;
}
.formFieldPhonePrefix label
{
  padding-left: 24px;
  font-size: 14px;
}
.formField input, .formField .MuiSelect-select{
  color: #344058 !important;
  font-size: 16px;
  line-height: 24px;
}
.formField input::-ms-reveal,.formField input::-ms-clear{display: none;}
.formField .MuiTypography-root{
   font-size: 18px;
  line-height: 24px;
}
.formField .MuiSelect-select{
  padding-bottom: 17.5px;
  padding-top: 17px
}
.MuiOutlinedInput-notchedOutline
{
  border-color:rgba(76,143,172,0.6) !important;
}
.MuiDialog-paperScrollPaper
{
  max-height: unset;
}
.radioGroup{
  text-align: right;
}
.multiSelectField{
  height: 100% !important;
  margin-bottom: -12px !important;
}
.multiSelectField .MuiFormHelperText-root.Mui-error{
  margin-bottom: -3px !important;
}
 .MuiFormHelperText-root.Mui-error
{
  margin-bottom: 10px;
}

.submitButton{
  margin-top: 40px;
  width: 100%;
}
/* .submitBut
{
  margin-top: 20px;
  background-image: linear-gradient(to right, #F7cd46 -9%,#fdba10 79%,#f9803b 153%);
  border-radius: 26px;
  padding: 11px 24px;
  border-color: transparent;
  cursor: pointer;
  float: left;
} */

.formRow{
  min-height: 96px;
  display: flex;
  width:100%;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.error{
  justify-content: center;
}
.error p{
  font-size: 17px;
  padding-top:10px;  
}
.backBut
{
  color:#435470;
  font-size: 18px;
  float:right;
}
.bottomDiv
{
  background-image: linear-gradient(to top,#a1b2c6 99%, rgba(135,152,173,0.5,rgba (135,152,173,0) 34%));
  box-shadow:0 -2px 8px 0 rgba(64,78,100,0.08);
  /* 2px -20px 24px 8px rgba(161,178,98,0.09); */
  height: 72px;
}

#ModLoginApp .MuiIconButton-root:hover
{
  background-color: transparent !important;
}
#ModLoginApp button:focus
{
  outline:none;
}
.p-tooltip .p-tooltip-text
{
  background-color: #fff;
}

/*form general classes*/
@media only screen and (max-width: 960px) {

  .formField{
    width:100%;    
  }
  .ModLogin .formRow.cols.phonecol
  {
    padding-right: unset;
  }
  .phoneSection,.phonecol .phoneSection
  {
    width:100%;    
  }
} 

@media (max-width: 441px)
{
  #ModLoginApp 
    {
        background: url('assets/images/background_mobile.png') no-repeat;
        background-size: contain;
        background-position-y: bottom;
        min-height: 50vh;
        display: block;
    }
}