
@media (min-width: 961px)
{
    .App {
        width: 85%;
        /* text-align: center; */
        max-width: 1000px;
        /* display: flex; */
      }
}
@media (min-width: 760px) and (max-width: 960px)
{
.App {
    max-width: 900px;
}
}

@media (max-width: 760px)
{
.App {
    max-width: 100%;
    /* margin-right: 15px;
    margin-left: 15px; */
}
}
