/*
 * Open Sans Hebrew (Hebrew) http://www.google.com/fonts/earlyaccess
 */
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 300;
  src: url(opensanshebrew-lightitalic-webfont.eot);
  src: url(opensanshebrew-lightitalic-webfont.eot?#iefix) format('embedded-opentype'),
       /* url(opensanshebrew-lightitalic-webfont.woff2) format('woff2'), */
       url(opensanshebrew-lightitalic-webfont.woff) format('woff'),
       url(opensanshebrew-lightitalic-webfont.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 300;
  src: url(opensanshebrew-light-webfont.eot);
  src: url(opensanshebrew-light-webfont.eot?#iefix) format('embedded-opentype'),
      /* url(opensanshebrew-light-webfont.woff2) format('woff2'),*/
       url(opensanshebrew-light-webfont.woff) format('woff'),
       url(opensanshebrew-light-webfont.ttf) format('truetype');
}
/*@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 400;
  src: url(opensanshebrew-Italic.eot);
  src: url(opensanshebrew-Italic.eot?#iefix) format('embedded-opentype'),
       url(opensanshebrew-Italic.woff2) format('woff2'),
       url(opensanshebrew-Italic.woff) format('woff'),
       url(opensanshebrew-Italic.ttf) format('truetype');
}*/
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 400;
  src: url(opensanshebrew-regular-webfont.eot);
  src: url(opensanshebrew-regular-webfont.eot?#iefix) format('embedded-opentype'),
       /* url(opensanshebrew-regular-webfont.woff2) format('woff2'), */
       url(opensanshebrew-regular-webfont.woff) format('woff'),
       url(opensanshebrew-regular-webfont.ttf) format('truetype');
}
/*@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 700;
  src: url(opensanshebrew-BoldItalic.eot);
  src: url(opensanshebrew-BoldItalic.eot?#iefix) format('embedded-opentype'),
       url(opensanshebrew-BoldItalic.woff2) format('woff2'),
       url(opensanshebrew-BoldItalic.woff) format('woff'),
       url(opensanshebrew-BoldItalic.ttf) format('truetype');
}*/
@font-face {
  font-family: 'Open Sans Hebrew bold';
  font-style: normal;
  font-weight: 700;
  src: url(opensanshebrew-bold-webfont.eot);
  src: url(opensanshebrew-bold-webfont.eot?#iefix) format('embedded-opentype'),
       /* url(opensanshebrew-bold-webfont.woff2) format('woff2'), */
       url(opensanshebrew-bold-webfont.woff) format('woff'),
       url(opensanshebrew-bold-webfont.ttf) format('truetype');
}
/*@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: italic;
  font-weight: 800;
  src: url(opensanshebrew-extraBoldItalic.eot);
  src: url(opensanshebrew-extraBoldItalic.eot?#iefix) format('embedded-opentype'),
       url(opensanshebrew-extraBoldItalic.woff2) format('woff2'),
       url(opensanshebrew-extraBoldItalic.woff) format('woff'),
       url(opensanshebrew-extraBoldItalic.ttf) format('truetype');
}*/
@font-face {
  font-family: 'Open Sans Hebrew';
  font-style: normal;
  font-weight: 800;
  src: url(opensanshebrew-extrabold-webfont.eot);
  src: url(opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
       /* url(opensanshebrew-extrabold-webfont.woff2) format('woff2'), */
       url(opensanshebrew-extrabold-webfont.woff) format('woff'),
       url(opensanshebrew-extrabold-webfont.ttf) format('truetype');
}
