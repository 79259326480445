.ModRgistration
{
    /* background-color: #fff;
    height: 100vh; */
}
.ModLogin .ModRgistration .formContainer
{
    width: 1200px;
}
.ModRgistration form
{
    position: relative;
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 47px;
}
.ModRgistration.oneform form
{
    flex: 100%;
    max-width: 100%;
}
.ModLogin  .ModRgistration .formField{
    width: 65%;
}
.ModLogin  .ModRgistration .formRow.cols .formField{
    width: 45%;
}
.ModLogin .ModRgistration .formRow.cols .formFieldPhone
{
  width:80%;
}
.ModLogin .ModRgistration .formRow.cols .formFieldPhonePrefix
{
  width:20%;
}
.ModRgistration .MuiFormControl-root
{
    margin:8px;

}
/* .ModLogin .formRow.cols.phonecol
{
    justify-content: start;
}
.phonecol .phoneSection
{
    width: 50%;
} */
.MuiFormHelperText-root
{
    color: red !important;
    margin-top: 15px;
}
.Hachvana h2:after{
    background-color: #1464a7;
    }
.RegistrationStatus h3
{
    font-size: 24px;
}
.RegistrationStatusDetails button
{
    background-color: transparent;
    border-radius: 8px;
    padding: 9px;
    margin-top: 15px;
    border-color: #425066;
    font-size: 17px;
}
.detailesBox
{
    font-size: 20px;
}
.telSection a
{
    display: none;
}
.telSection span
{
    display: block;
}

.hachvana-tooltip.p-tooltip.p-tooltip-left  .p-tooltip-arrow{
    border-left-color: #bf350b;
}
.p-tooltip .p-tooltip-text 
{
    background: #Fff !important;
    color:#000 !important;
    border:1px solid #bf350b;
}
.Hachvana .submitBut
{
    background-image: linear-gradient(to right, #257db8 -9%,#257db8 79%,#1464a7 153%);
}
.Hachvana .before_start
{
    text-align:left;
    color:#bf350b;
}
fieldset
{
    padding: 20px;
}
@media only screen and (max-width: 960px) {

    
.ModLogin  .ModRgistration .formField,
.ModLogin .ModRgistration .formRow.cols .formField{
    width: 100%;
}

.ModLogin .ModRgistration .formContainer
    {
        max-width: 602px;
        width: unset;
    }
  } 
  

@media only screen and (max-width: 580px) {
    
    .telSection span
    {
        display: none;
    }
    .telSection a
    {
        display: block;
    }
}