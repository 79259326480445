
.loginwrapper
{
   display: flex;
   justify-content: center;
}

.ModLogin .MuiCircularProgress-colorPrimary
{
  color:#1c5dbe !important;
}
.ModLogin h1{
    color: #000;
    font-size: 60px;
    line-height: 73px;
    font-weight: 700;
    text-align: center;  
    max-width: 1251px;  
    margin-top: 35px;
}
.ModLogin h2
{
    color: #000;
    font-size: 35px;
    line-height: 42px;
    font-weight: 500;
    text-align: center;
   
    max-width: 1251px;

    /* padding-right: 56px; */
    padding-bottom: 61px;
    /* padding-top: 83px; */
}
.ModLogin h2:after
{
    background: none;
}
.mainsitelink
{
    color: #000;
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    text-align: center;
    text-decoration: underline;
    max-width: 1251px;
    display: block;
    padding-top: 54px;
    padding-bottom: 205px;
}
.ModLogin .logincontainer{
    
    display: flex;
    justify-content: center;
    max-width: 1251px;
    /* padding-right: 33px; */
}
.moreinfocontainer
{
    display: flex;
    max-width: 1251px;
    justify-content: center;
    background-color: white;
    border-radius: 51px;
    border: 1px solid #464141;
    padding: 30px;
    margin-top: 30px;
}
.ModLogin .sms
{
    justify-content: center;
}

.ModLogin form
{
    position: relative;
    display: flex;
    /* flex: 0 0 50%;
    max-width: 50%; */
    /* padding-left: 47px; */
}
.ModLogin .oneform form
{
    flex: 100%;
    max-width: 100%;
}
.ModLogin .formContainer
{
   
    width: 602px;
    min-height: 476px;
    background: #ece5e5;
    border-radius: 51px;
    border-color: #000;
    flex: 1;
}
.ModLogin .forgotpass fieldset:first-child
{
    padding: 10px 16px 16px 46px;
}
.ModLogin .formRow
{
    justify-content: center;
}
.ModLogin .MuiOutlinedInput-root
{
    border-radius: unset;
}
.ModLogin .sms .formRow
{
    align-content: center;
    height: 50%;
}
.ModLogin .sms fieldset
{
    flex:50%;
}
.ModLogin .formRow.cols 
{
    flex: 0.45 1;
    
}
.ModLogin .formRow.cols .formField:first-of-type
{
    margin-left: 30px;
}
.ModLogin .forgotpass .formField
{
    width: 100%;
    display: flex;
    flex: 1;
}
.ModLogin legend
{
    color: #000;
    font-size: 36px;
    line-height: 44px;
    font-weight: 400;
    padding-top: 26px;
    text-align: center;
}
.ModLogin input:-webkit-autofill,
.ModLogin input:-webkit-autofill:focus,
.ModLogin input:-webkit-autofill:hover,
.ModLogin input:-webkit-autofill:active
{
   -webkit-box-shadow:0 0 0px 1000px #ece5e5 inset !important

}
input[data-autocompleted]
{
    background-color: transparent !important;
}
.ModLogin .MuiInput-input,input:-internal-autofill-selected,.MuiInputBase-input .MuiInput-input
{
    background-color: transparent !important;
}
.ModLogin .MuiOutlinedInput-notchedOutline
{
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: #000 !important;
    
}
.ModLogin .formField input
{
    color: #464141 !important;
  font-size: 24px;
  line-height: 29px;

}
.ModLogin .MuiIconButton-root
{
    color: #000;
    width: 30px;
    height: 16px;
}
.MuiFormLabel-root.formlabel
{
    font-size: 34px;
  line-height: 1;
}
.ModLogin .MuiInput-underline::before
{
    border-bottom: 1px solid #000;
}
.ModLogin .MuiInput-underline:after
{
    border-bottom: 2px solid #000;
}
  .ModLogin .submitBut
  {
    
    background-image: linear-gradient(180deg, #1c5d8e 0%,#809ca8 100%);
    border-radius: 33px;
    max-width: 393px;
    width: 60%;
    height: 75px;
    
    /* position: absolute;
    right: 12%;
    bottom: 75px; */
    border-color: transparent;
    cursor: pointer;
    
    color: #fff;
    font-size: 25px;
    line-height: 30px;
    font-weight: 400;
  }
  .ModLogin .submitWrap
  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
  }
  .ModLogin .submitWrap.smsbtnWrapper
  {
      flex-direction: column;
  }
  .ModLogin .submitBut:hover,.ModLogin .submitBut:focus
  {
    background-image:linear-gradient(180deg, #1c5d8e 70%,#809ca8 100%);
    border: 1px solid palevioletred;
  }
  .ModLogin .smsbtns
  {
    position: unset;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
    align-items: center;
  }
  .ModLogin .orcircle
  {
    width: 68px;
    height: 68px;
    background-color: #304b60;
    color: #ede6e6;
    font-size: 24px;
    line-height: 68px;
    font-weight: 600;
    position: absolute;
    z-index: 100;
    border-radius: 50%;
    right: -58px;
    /* top: -10px; */
    bottom: 40%;
    text-align: center;
  }
  .ModLogin .iconSignin
  {
    width: 85px;
    height: 85px;
    background-color: #fff;
    position: absolute;
    z-index: 100;
    border-radius: 50%;
    right: -18px;
    top: -10px;
  }

  .ModLogin .iconSignin img{
    display: block;
    margin: 0 auto;
    margin-top: 30%;
  }
  .ModLogin .formField{
    width:65%;    
  }
  .ModLogin .mobileview form
  {
      max-width: 100%;
  }
  .ModLogin .mobileview .formContainer
  {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 1px solid #ccc9c9;
  }
  .resentcode
  {   
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    }
    .resentcode label
    {
        margin: unset;
        margin-right: 5px;
    }
    .resentcode .smsbtns
    {
        background-image: linear-gradient(
            180deg
            , #1c5d8e 0%,#809ca1 80%);
        border-radius: 33px;
        border-color: transparent;
        cursor: pointer;
        color: #fff;
        line-height: 30px;
        font-weight: 400;
        padding: 0 0.5rem;
    }
    .resentcode .smsbtns:hover, .resentcode .smsbtns:focus {
        background-image: linear-gradient(
    180deg
    , #1c5d8e 70%,#809ca8 100%);
        border: 1px solid palevioletred;
        line-height: 31px;
    }
  .forgotpasswordlink
  {
    width: 65%;
    display: flex;
    justify-content: start;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #464141;
    padding-top: 30px;
    padding-bottom: 30px;
    text-decoration: underline;

    border:none;
    background: none;
  }
  .registerLink
  {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    text-decoration: underline;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #464141;
  }
  header{
    display: flex;
    justify-content: space-around;
}
  .imgLogo
{
    
    max-height: 112px;
    padding: 10px;
}
.otp-field
{
    display: flex;
    width:100%;
    max-width: 100%;
    grid-column-gap: 10px;
    column-gap: 10px;
    direction: ltr;
    justify-content: space-evenly;
}
.otp-input
{
    width: 60px;
    height: 60px;
    border:1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
}
.pagenotfound
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pagenotfound h1{
    font-size: 15rem;
    line-height: unset;
}
.pagenotfound h2,.pagenotfound h3{
    font-size: 2rem;
    line-height: unset;
    padding-bottom: unset;
}
.pagenotfound h2::after{
background: none;
width: fit-content;
}
.errorpage img
{
    width: 100%;
}
@media (min-width: 961px)
{
    .App {
        max-width: none;
        width: 100%; 
    }
}
@media screen and (max-width: 1240px) and (min-width:650px)
{
    .ModLogin .formContainer
    {
        max-width: 602px;
        width: unset;
    }
    .ModLogin .oneform .formContainer
    {
        max-width: 100%;
        width: 602px;
    }
}
@media (max-width: 996px)
{
    
    header{
        background: #ece5e5;
        height: 104px;
        display: flex;
        justify-content: space-between;
    }
    header .imgLogo.imgLogo
    {
        max-height: 80px;
    }
    .ModLogin h1
    {
        font-size: 40px;
        line-height: 48px;
    }
    .ModLogin form
    {
        flex: 100%;
        max-width: 100%;
    }
    .ModLogin legend
    {
        font-size: 26px;
    }
    .ModLogin .submitBut
    {
        bottom: 25px;
    }
    .ModLogin .iconSignin
    {
        right: -28px;
    top: -25px;
    }
    .ModLogin .formContainer
    {
        max-width: 602px;
        width: unset;

    }
    .ModLogin .mobileheader
    {
        display: flex;
        padding-left:22px;
        padding-right: 22px;
    }
    .headerbackground
    {
        background: #ece5e5;
        border-top-right-radius: 51px;
        border-top-left-radius: 51px;
        height: 72.5px;
        max-width: 602px;
        flex: 100%;
        display: inline-flex;
        justify-content: space-between;
    }
    .tabBtn
    {
        flex: 50%;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: #464141;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .tabBtn.active
    {
        border-bottom: 4px solid #1c5d8e;
    }
    .ModLogin form
    {
        padding-left: 22px;
        padding-right: 22px;
    }
}
@media (max-width: 780px)
{
    .moreinfocontainer
    {
        margin: 30px 20px;
    }
    .ModLogin .logincontainer
    {
        padding-right: unset;
    }
    .ModLogin .formField{
        width:85%;    
      }
    /* .ModLogin fieldset,.ModLogin .oneform form
    {
        padding: unset;
    } */
    .otp-input
    {
        width: 40px;
    }
}