
.ModSnackbar .successSnack{
    background-color: #43a047;
}
.ModSnackbar .infoSnack{
    background-color: #3f51b5;
}
.ModSnackbar .errorSnack{
    background-color: #d32f2f;
}
.ModSnackbar .warningSnack{
    background-color: #ffa000
}
.ModSnackbar .message{
    display: flex;
    align-items: center;
    font-size: 20px;
}
.ModSnackbar .iconVariant{
    opacity:0.9;
    margin-left: 8px;
}
.ModSnackbar .icon{
    font-size: 20;
}
@media (min-width: 600px)
{
.MuiSnackbar-anchorOriginTopRight
{
    top:500px;
    right:300px;
    justify-content: center;
}
.MuiSnackbar-anchorOriginTopCenter {
    top:300px;
}
}